import React, { useState, useEffect } from 'react';
import { BsArrowLeft, BsArrowRight, BsLockFill } from "react-icons/bs";
import { allCountries } from 'utils/countryList';
import bm2payLogo from "./assets/img/bm2pay.svg";
import lightningChecksLogo  from "./assets/img/mainlogo.png";
import paxumLogo  from "./assets/img/paxum.png";
import { createPayment, getCheckOutDetails, getClient , generateCheckoutLink, checkLink } from "api/clients";
import { useParams } from 'react-router-dom';
import Link from 'antd/es/typography/Link';
import toast from 'react-hot-toast';
import pix from 'assets/img/payment-clients/pix.png';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle } from 'react-icons/ai';

interface PaymentData {
    first_name:string;
    middle_name:string;
    last_name:string;
    name: string;
    email: string;
    phone: string;
    cardHolderName: string;
    cardNumber: string;
    expiry: string;
    cvv: string;
    address: string;
    state: string;
    city: string;
    country: string;
    postalCode: string;
}

interface PixPaymentData {
    first_name:string;
    last_name:string;
    identification_number: string;
    identification_type: string;
}

const hostname = window.location.hostname;
const logos : any = {
    'gateway-dev.lightningchecks.com': lightningChecksLogo,
    'gateway.lightningchecks.com': lightningChecksLogo,
    'testing.lightningchecks.com': lightningChecksLogo,
    'gateway-dev.bm2pay.com': bm2payLogo,
    'gateway.bm2pay.com': bm2payLogo,
    'gateway-stagging.bm2pay.com': bm2payLogo,
    'pay.paxum.com': paxumLogo,
};
const mainlogo = (
    <img
        style={{ height: "auto", width: hostname === "pay.paxum.com" ? "80px" : "30px" }}
        src={logos[hostname] || bm2payLogo}
        className="ml-2"
        alt="Gateway Image"
    />
);

function CheckoutPage() {
    const { client_id, checkout_id, unique_payment_link_id } = useParams()
    const [client_secret,setClientSecret] = useState('');
    const [brandColor, setBrandColor] = useState('#2020e4');
    const [style, setStyle] = useState("rounded");
    const [selectedFont, setSelectedFont] = useState("'Poppins'");
    const [showForm,setShowForm] = useState(false);
    const [paymentType,setPaymentType] = useState('one-time-payment');
    const [paymentData, setPaymentData] = useState<PaymentData>({
        first_name: "",
        middle_name: "",
        last_name: "",
        name: "",
        email: "",
        phone: "",
        cardHolderName : '',
        cardNumber: '',
        expiry : '',
        cvv : '',
        address : '',
        state : '',
        city : '',
        country : 'CA',
        postalCode : ''
    });
    const [pixPaymentData, setPixPaymentData] = useState<PixPaymentData>({
        first_name: "",
        last_name: "",
        identification_number: "",
        identification_type: ""
    });
    const [timeLeft, setTimeLeft] = useState(0); 
    const [isExpired, setIsExpired] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<string>("");
    const [amount, setAmount] = useState("");
    const [amountNotSet, setAmountNotSet] = useState(false);
    const [targetDateTime, setTargetDateTime] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);
    const [showPreview,setShowPreview] = useState(true);
    const [successPreview,setSuccessPreview] = useState(false);
    const [pendingPreview,setPendingPreview] = useState(false);
    const [failedPreview,setFailedPreview] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [isUsed, setIsUsed] = useState(false);
    
    const [checkoutPaymentMethod,setCheckoutPaymentMethod] = useState(null);
    useEffect(() => {
        if (targetDateTime) {
            const targetDate: any = new Date(targetDateTime); 
            
    
            let intervalId: NodeJS.Timeout; 
            
            const updateTimeLeft = () => {
                
                const currentTime: any = new Date();
                
                const difference = targetDate - currentTime;
    
                if (difference <= 0) {
                    setIsExpired(true);
                    setTimeLeft(0);
                    clearInterval(intervalId); 
                    generateNewLink();
                } else {
                    setTimeLeft(difference);
                }
            };
    
            updateTimeLeft(); // Initial call
            intervalId = setInterval(updateTimeLeft, 1000); 
    
            return () => clearInterval(intervalId); 
        }
    }, [targetDateTime]);

  

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (client_id) {
                    console.log("Client ID:", client_id); // Debug: Log client_id
    
                    const clientResponse = await getClient(client_id);
                    console.log("Client Response:", clientResponse); // Debug: Log clientResponse
    
                    const client_secret = clientResponse[0]?.client_secret;
                    if (!client_secret) {
                        console.error("Client secret is undefined");
                        return;
                    }
    
                    setClientSecret(client_secret);
                    setPaymentData((prevData) => ({ ...prevData, name: clientResponse[0]?.name }));
    
                    const checkLinkResponse = await checkLink(client_id, checkout_id, unique_payment_link_id, client_secret);
                    console.log("Check Link Response:", checkLinkResponse); // Debug: Log checkLinkResponse
    
                    const isUsed = checkLinkResponse?.data?.is_used;
                    setIsUsed(isUsed);
    
                    if (!isUsed) {
                        const checkoutResponse = await getCheckOutDetails(checkout_id, client_id, client_secret);
                        console.log("Checkout Response:", checkoutResponse); // Debug: Log checkoutResponse
    
                        const responseData = checkoutResponse?.data;
                        if (!responseData) {
                            console.error("Response data from getCheckOutDetails is undefined");
                            return;
                        }
    
                        setBrandColor(responseData?.brand_color);
                        setStyle(responseData?.brand_style);
                        setSelectedFont(responseData?.brand_font);
                        setSelectedCurrency(responseData?.currency);
                        setAmount(responseData?.amount);
    
                        if (responseData?.amount == null) {
                            setAmountNotSet(true);
                        }
    
                        const targetDateFromAPI = new Date(responseData?.expires_at);
                        setTargetDateTime(targetDateFromAPI);
                        setExpiresAt(responseData?.expires_at);
                        setCheckoutPaymentMethod(responseData?.checkout_payment_method);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, [client_id]);
    
    

    
    
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    const getBorderRadiusClass = () => {
        if (style === "sharp") {
          return "rounded-none";
        } else if (style === "pill") {
          return "rounded-full";
        } else {
          return "rounded";
        }
    };

    const getBorderRadiusCardClass = () => {
        if (style === "sharp") {
          return "rounded-none";
        } else if (style === "pill") {
          return "rounded-[25px]";
        } else {
          return "rounded";
        }
    };

    const handleCardNumberChange = (e : any) => {
        let input = e.target.value.replace(/\D/g, "");
        input = input.slice(0, 16);
        const formattedInput = input.replace(/(.{4})/g, "$1 ").trim();
        setPaymentData({ ...paymentData, cardNumber: formattedInput })
    };
    
    const handleExpiryDateChange = (e : any) => {
        let input = e.target.value.replace(/\D/g, "");
        input = input.slice(0, 4);
        if (input.length > 2) {
          input = `${input.slice(0, 2)} / ${input.slice(2)}`;
        }
        setPaymentData({ ...paymentData, expiry: input })
    };
    
    const handleCvvChange = (e : any) => {
        let input = e.target.value.replace(/\D/g, "");
        input = input.slice(0, 4);
        setPaymentData({ ...paymentData, cvv: input })
    };

    const handleCountryChange = (e : any) => {
        setPaymentData({ ...paymentData, country: e.target.value })
    };

    const handlePayNow   = () => {
        setIsProcess(true);
        if(paymentType == 'one-time-payment') {
            const [month, year] = paymentData.expiry.split(" / ").map((part) => part.trim());
            const payload = {
                "payment_type": 'ONE_TIME',
                "checkout_details": {
                    "id": checkout_id,
                    "expires_at": expiresAt,
                    "client_id": client_id
                },
                "payment_request_data": {
                    "first_name": paymentData.first_name,
                    "middle_name": paymentData.middle_name,
                    "last_name": paymentData.last_name,
                    "email": paymentData.email,
                    "phone": paymentData.phone,
                    "city": paymentData.city,
                    "address": paymentData.address,
                    "postal_code": paymentData.postalCode,
                    "state": paymentData.state,
                    "country": paymentData.country,
                    "ip": "0.0.0.0",
                    "cardholder_name": paymentData.cardHolderName,
                    "card_number": paymentData.cardNumber.replace(/\s+/g, ''),
                    "expiration_month": month,
                    "expiration_year": year,
                    "cvv": paymentData.cvv,
                    "amount": parseFloat(amount).toFixed(2),
                    "currency": selectedCurrency
                }
            }
            createPayment(payload,client_id,checkout_id,unique_payment_link_id,client_secret)
            .then((response) => {
                setIsProcess(false);
                if(response.status == false) {
                    toast.error(
                        response?.message ??
                        "Something went wrong while cancelling subscription"
                    );
                } else {
                    const { initiated, message } = response.data.payment_response;

                    const isObject = (value : any) => value && typeof value === 'object' && !Array.isArray(value);

                    let isError;
                    if (initiated) {
                        isError = !isObject(message);
                    } else {
                        isError = !isObject(message);
                    }
                    if(isError) {
                        toast.error('something went wrong please contact admin');
                    } else {
                        const paymentData = response?.data?.payment_response
                        const status = paymentData?.message?.status;
                        if(status == 'DECLINED' || status == 'CANCELLED' || status == 'REJECTED' || status == 'ERRORED') {
                            setShowPreview(false);
                            setFailedPreview(true);
                            setSuccessPreview(false);
                            setPendingPreview(false);
                        } else if(status == 'APPROVED') {
                            setShowPreview(false);
                            setFailedPreview(false);
                            setSuccessPreview(true);
                            setPendingPreview(false);
                        } else if(status == 'PENDING') {
                            setShowPreview(false);
                            setFailedPreview(false);
                            setSuccessPreview(false);
                            setPendingPreview(true);
                        }

                    }
                }
            })
            .catch((error) => {
                console.error("Error creating payment:", error);
            });

        } else if(paymentType == 'pix-payment') {
            const payload = {
                "payment_type": 'PIX',
                "checkout_details": {
                    "id": checkout_id,
                    "expires_at": expiresAt,
                    "client_id": client_id
                },
                "payment_request_data": {
                    "sender_details" : {
                        "first_name": pixPaymentData.first_name,
                        "last_name": pixPaymentData.last_name,
                        "identification_number": pixPaymentData.identification_number,
                        "identification_type": pixPaymentData.identification_type,
                    },
                    "payin_information" : {
                        "amount": parseFloat(amount).toFixed(2),
                        "currency": 'real'
                    }
                }
            }
            createPayment(payload,client_id,checkout_id,unique_payment_link_id,client_secret)
            .then((response) => {
                setIsProcess(false);
                if(response.status == false) {
                    toast.error(
                        response?.message ??
                        "Something went wrong while cancelling subscription"
                    );
                } else {
                    // toast.success(
                    //     response?.message
                    // );

                    const { initiated, message } = response.data.payment_response;

                    const isObject = (value : any) => value && typeof value === 'object' && !Array.isArray(value);

                    let isError;
                    if (initiated) {
                        isError = !isObject(message);
                    } else {
                        isError = !isObject(message);
                    }
                    console.log("isError",isError);
                    if(isError) {
                        toast.error('something went wrong please contact admin');
                    } else {

                        const paymentData = response?.data?.payment_response
                        const status = paymentData?.message?.status;

                        if(status == 'DECLINED' || status == 'CANCELLED' || status == 'REJECTED' || status == 'ERRORED') {
                            setShowPreview(false);
                            setFailedPreview(true);
                            setSuccessPreview(false);
                            setPendingPreview(false);
                        } else if(status == 'APPROVED') {
                            setShowPreview(false);
                            setFailedPreview(false);
                            setSuccessPreview(true);
                            setPendingPreview(false);
                        } else if(status == 'PENDING') {
                            setShowPreview(false);
                            setFailedPreview(false);
                            setSuccessPreview(false);
                            setPendingPreview(true);
                        }

                    }
                }
            })
            .catch((error) => {
                console.error("Error creating payment:", error);
            });
        }
        
    } 

    const handleNext = () => {
        setShowForm(true);
    }

    const handleGoBack = () => {
        setShowForm(false);
    }

    const generateNewLink = () => {
        const payload = {
            "checkout_id": checkout_id
        }
        generateCheckoutLink(payload,client_id,client_secret)
        .then((response) => {
            let updatedUrl = '';

            if (window.location.hostname === "gateway-dev.lightningchecks.com") {
                const newBaseUrl = "https://gateway-dev.lightningchecks.com/";
                updatedUrl  = response?.data?.payment_link.replace(
                "https://backend-dev.bm2pay.com/api/v1/",
                newBaseUrl
                );
            } else if (window.location.hostname === "gateway.lightningchecks.com") {
                const newBaseUrl = "https://gateway.lightningchecks.com/";
                updatedUrl  = response?.data?.payment_link.replace(
                "https://backend.lightningchecks.com/api/v1/",
                newBaseUrl
                );
            } else if (window.location.hostname === "gateway-dev.bm2pay.com") {
                const newBaseUrl = "https://gateway-dev.bm2pay.com/";
                updatedUrl  = response?.data?.payment_link.replace(
                "https://backend-dev.bm2pay.com/api/v1/",
                newBaseUrl
                );
            } else if (window.location.hostname === "gateway.bm2pay.com") {
                const newBaseUrl = "https://gateway.bm2pay.com/";
                updatedUrl  = response?.data?.payment_link.replace(
                "https://backend.bm2pay.com/api/v1/",
                newBaseUrl
                );
            } else if (window.location.hostname === "pay.paxum.com") {
                const newBaseUrl = "https://pay.paxum.com/";
                updatedUrl  = response?.data?.payment_link.replace(
                "https://backend.paxum.com/api/v1/",
                newBaseUrl
                );
            } else {
                // const newBaseUrl = "https://gateway-dev.bm2pay.com/";
                const newBaseUrl = "http://localhost:3000/";
                updatedUrl  = response?.data?.payment_link.replace(
                "https://backend-dev.bm2pay.com/api/v1/",
                newBaseUrl
                );
            }

            if (updatedUrl) {
                window.location.href = updatedUrl;
            }
        })
        .catch((error) => {
            console.error("Error generating new link:", error);
        });
    }

    return (
        <>
        {!isUsed ? (
            !showForm ? (
            <div className="flex flex-col lg:flex-row  mx-auto h-[100vh]">
                {showPreview ? (
                    <>
                        <div className="h-full md:w-2/6 lg:w-2/6 sm:w-full border-r-2 p-5" style={{ backgroundColor: brandColor , color : '#FFFFFF' }}>
                            <div className="flex flex-col h-full justify-center items-center gap-6">
                                <img
                                style={{
                                    height: "auto",
                                    width: hostname === "pay.paxum.com" ? "80px" : "150px",
                                    filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.15))",
                                }}
                                src={logos[hostname] || bm2payLogo}
                                alt="Gateway Image"
                                />
                                <div className="text-center">
                                <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>Fast. Secure. Reliable.</h3>
                                <p className="text-lg text-indigo-200 italic" style={{ fontFamily: selectedFont }}>Your trusted gateway for seamless payments.</p>
                                </div>
                                <div className="text-center">
                                    <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>
                                        {isExpired ? (
                                        <div className="flex items-center gap-x-2">
                                            <span>Generating new link.....</span>
                                            {/* <button
                                                className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200  active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                                                onClick={generateNewLink}
                                            > 
                                                Generate New Link
                                            </button> */}
                                        </div>
                                        ) : (
                                        `Expires At : ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                                        )}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div  className="h-full md:w-4/6 lg:w-4/6 sm:w-full px-14 py-10">
                            <div className="flex flex-col h-full">
                                <h3 
                                    style={{ fontFamily: selectedFont }} 
                                    className="mb-6 text-2xl font-bold text-gray-800 dark:text-gray-100"
                                >
                                    Choose Payment Type
                                </h3>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                                    {checkoutPaymentMethod == null ? (
                                        <>
                                            <div
                                            className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                paymentType === 'one-time-payment'
                                                ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                : 'border-gray-200 hover:border-gray-400'
                                            }`}
                                            onClick={() => setPaymentType('one-time-payment')}
                                            >
                                            <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                One Time Payment
                                            </h3>
                                            <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                A single payment option for a one-time transaction.
                                            </p>
                                            </div>
                                            <div
                                            className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                paymentType === 'pix-payment'
                                                ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                : 'border-gray-200 hover:border-gray-400'
                                            }`}
                                            onClick={() => setPaymentType('pix-payment')}
                                            >
                                            <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                PIX Payment
                                            </h3>
                                            <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                Use PIX for secure and instant payment.
                                            </p>
                                            </div>
                                        </>
                                    ) : (
                                        checkoutPaymentMethod.map((method: any, index: number) => {
                                            if (method === 'ONE_TIME') {
                                                return (
                                                    <div
                                                    key={index}
                                                    className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                        paymentType === 'one-time-payment'
                                                        ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                        : 'border-gray-200 hover:border-gray-400'
                                                    }`}
                                                    onClick={() => setPaymentType('one-time-payment')}
                                                    >
                                                        <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                            One Time Payment
                                                        </h3>
                                                        <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                            A single payment option for a one-time transaction.
                                                        </p>
                                                    </div>  
                                                )
                                            }

                                            if (method === 'PIX') {
                                                return (
                                                    <div
                                                    key={index}
                                                    className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                        paymentType === 'pix-payment'
                                                        ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                        : 'border-gray-200 hover:border-gray-400'
                                                    }`}
                                                    onClick={() => setPaymentType('pix-payment')}
                                                    >
                                                        <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                            PIX Payment
                                                        </h3>
                                                        <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                            Use PIX for secure and instant payment.
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        })
                                    )}
                                </div>
                                <div className="flex justify-end mt-6">
                                    <button
                                    className={`flex ${getBorderRadiusClass()} items-center gap-3 px-6 py-3 text-white text-sm font-medium shadow-md transition-transform transform hover:scale-105`}
                                    style={{
                                        backgroundColor: brandColor,
                                        boxShadow: `0px 10px 15px -3px ${brandColor}66, 0px 4px 6px -4px ${brandColor}33`,
                                    }}
                                    onClick={handleNext}
                                    disabled={isExpired}
                                    >
                                    <span style={{ fontFamily: selectedFont }} >Next</span>
                                    <BsArrowRight className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : successPreview ? (
                    <div
                        className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                        style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                    >
                        <AiFillCheckCircle className="w-20 h-20 mb-4" />
                        <span className="text-xl font-semibold mb-4">Payment Success</span>
                    </div>
                ) : pendingPreview ? (
                    <div
                        className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                        style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                    >
                        <AiFillExclamationCircle  className="w-20 h-20 mb-4" />
                        <span className="text-xl font-semibold mb-4">Payment Pending</span>
                    </div>
                ) : failedPreview ? (
                    <div
                        className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                        style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                    >
                        <AiFillCloseCircle  className="w-20 h-20 mb-4" />
                        <span className="text-xl font-semibold mb-4">Payment Failed</span>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            ) : (
            <div className={`flex flex-col lg:flex-row mx-auto ${paymentType === 'pix-payment' ? 'h-[100vh]' : ''}`}>
                {showPreview ? (
                    <>
                        <div  className="w-full md:w-2/6 lg:w-2/6 sm:w-full border-r-2 px-16 py-10 "  style={{ backgroundColor: brandColor , color : '#FFFFFF' }}>
                            <h3 
                                className="flex text-[22px] font-bold items-center" 
                                style={{ fontFamily: selectedFont }}
                            >
                                <BsArrowLeft onClick={handleGoBack} className="mr-2 cursor-pointer" /> {paymentData?.name}
                            </h3>
                            <div className="flex justify-between items-center">
                                <h2 className="text-[50px] font-bold my-4" style={{ fontFamily : selectedFont }}>{amount} {selectedCurrency}</h2>
                                
                                <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>
                                    {isExpired ? (
                                        <span>Generating new link.....</span>
                                    ) : (
                                        `Expires At : ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                                    )}
                                </h3>
                            </div>

                        </div>
                        <div className="w-full sm:w-full md:w-4/6 lg:w-4/6 px-5 py-10 md:px-28 md:py-10 lg:px-28 lg:py-10 ">
                            <div className="flex flex-col space-y-6">
                                {paymentType == 'one-time-payment' ? ( 
                                    <>
                                        <h3 className="text-xl font-semibold mb-4 relative text-center" style={{ fontFamily: selectedFont }}>
                                            <span className="absolute left-0 top-1/2 w-full border-t border-gray-300" style={{ transform: 'translateY(-50%)' }}></span>
                                            <span className="bg-white px-2 relative z-10" style={{ fontFamily: selectedFont }} >Pay with card</span>
                                        </h3>
                                        <div className="flex gap-4 mb-5">
                                            <div className="flex flex-col w-full">
                                                <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                    First Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                    style={{ fontFamily: selectedFont }}
                                                    placeholder="first name"
                                                    value={paymentData?.first_name}
                                                    onChange={(e) => setPaymentData({ ...paymentData, first_name: e.target.value })}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                    Middle Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                    style={{ fontFamily: selectedFont }}
                                                    placeholder="middle name"
                                                    value={paymentData?.middle_name}
                                                    onChange={(e) => setPaymentData({ ...paymentData, middle_name: e.target.value })}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                    style={{ fontFamily: selectedFont }}
                                                    placeholder="first name"
                                                    value={paymentData?.last_name}
                                                    onChange={(e) => setPaymentData({ ...paymentData, last_name: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-4 mb-5">
                                            <div className="flex flex-col w-full">
                                                <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                    style={{ fontFamily: selectedFont }}
                                                    placeholder="test@gmail.com"
                                                    value={paymentData?.email}
                                                    onChange={(e) => setPaymentData({ ...paymentData, email: e.target.value })}
                                                />
                                            </div>
                                    
                                            <div className="flex flex-col w-full">
                                                <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                    Phone Number
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                    style={{ fontFamily: selectedFont }}
                                                    placeholder="1234567890"
                                                    value={paymentData?.phone}
                                                    onChange={(e) => setPaymentData({ ...paymentData, phone: e.target.value })}
                                                />
                                            </div>
                                            {amountNotSet && (
                                                <div className="flex flex-col w-full">
                                                    <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                        Amount
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                        style={{ fontFamily: selectedFont }}
                                                        placeholder="amount"
                                                        value={amount}
                                                        onChange={(e) => setAmount(e.target.value )}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex flex-col">
                                            <label
                                                className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200"
                                                style={{ fontFamily: selectedFont }}
                                            >
                                                Card information
                                            </label>
                                            <div
                                                className={`border ${getBorderRadiusCardClass()} border-gray-300 dark:border-white/10 focus-within:ring-2 focus-within:ring-blue-500 transition-all shadow-sm hover:shadow-lg transition-shadow`}
                                            >
                                                <div className=" border-b-[1px]">
                                                <input
                                                    type="text"
                                                    style={{ fontFamily: selectedFont }}
                                                    className={`w-full p-4 border-transparent ${getBorderRadiusCardClass()} outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                    placeholder="Card Holder Name"
                                                    value={paymentData?.cardHolderName}
                                                    onChange={(e) => setPaymentData({ ...paymentData, cardHolderName: e.target.value })}
                                                />
                                                </div>

                                                <div className=" border-b-[1px]">
                                                <input
                                                    type="text"
                                                    style={{ fontFamily: selectedFont }}
                                                    className="w-full p-4 border-none outline-none placeholder-gray-400 dark:placeholder-gray-500"
                                                    placeholder="1234 1234 1234 1234"
                                                    value={paymentData?.cardNumber}
                                                    onChange={handleCardNumberChange}
                                                />
                                                </div>

                                                <div className="flex gap-4">
                                                <input
                                                    type="text"
                                                    style={{ fontFamily: selectedFont }}
                                                    className={`w-1/2 p-4 border-r-[1px] rounded-r-none border-transparent ${getBorderRadiusCardClass()} outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                    placeholder="MM / YY"
                                                    value={paymentData?.expiry}
                                                    onChange={handleExpiryDateChange}
                                                />
                                                <input
                                                    type="text"
                                                    className={`w-1/2 p-4 border-transparent ${getBorderRadiusCardClass()} outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                    placeholder="CVC"
                                                    style={{ fontFamily: selectedFont }}
                                                    value={paymentData?.cvv}
                                                    onChange={handleCvvChange}
                                                />
                                                </div>
                                            </div>
                                        </div>



                                        <div className="flex flex-col">
                                        <label
                                                className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200"
                                                style={{ fontFamily: selectedFont }}
                                            >
                                                Address information
                                            </label>
                                            <div
                                                className={`border ${getBorderRadiusCardClass()} border-gray-300 dark:border-white/10 focus-within:ring-2 focus-within:ring-blue-500 transition-all shadow-sm hover:shadow-lg transition-shadow`}
                                            >
                                                <div className=" border-b-[1px]">
                                                    <input
                                                    type="text"
                                                    style={{ fontFamily: selectedFont }}
                                                    className={`border-transparent ${getBorderRadiusCardClass()} p-4 w-full  outline-none placeholder-gray-400 dark:placeholder-gray-500 `}
                                                    placeholder="Address"
                                                    value={paymentData?.address}
                                                    onChange={(e) => setPaymentData({ ...paymentData, address: e.target.value })}
                                                    />
                                                </div>
                                                <div className="flex gap-4 border-b-[1px]">
                                                    <input
                                                    type="text"
                                                    style={{ fontFamily: selectedFont }}
                                                    className={` border-r-[1px] p-4 w-1/2 outline-none placeholder-gray-400 dark:placeholder-gray-500 `}
                                                    placeholder="State"
                                                    value={paymentData?.state}
                                                    onChange={(e) => setPaymentData({ ...paymentData, state: e.target.value })}
                                                    />
                                                    <input
                                                    type="text"
                                                    style={{ fontFamily: selectedFont }}
                                                    className={`border-none p-4 w-1/2 outline-none placeholder-gray-400 dark:placeholder-gray-500 `}
                                                    placeholder="City"
                                                    value={paymentData?.city}
                                                    onChange={(e) => setPaymentData({ ...paymentData, city: e.target.value })}
                                                    />
                                                </div>
                                                <div className="flex gap-4">
                                                <select
                                                className={`border-transparent ${getBorderRadiusCardClass()} rounded-r-none border-r-[1px] p-4 w-1/2 outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                onChange={handleCountryChange}
                                                style={{ fontFamily: selectedFont }}
                                                value={paymentData?.country}
                                                >
                                                {allCountries.map((country) => (
                                                    <option key={country.code} value={country.code}>
                                                    {country.name}
                                                    </option>
                                                ))}
                                                </select>
                                                <input
                                                type="text"
                                                style={{ fontFamily: selectedFont }}
                                                className={`border-transparent ${getBorderRadiusCardClass()} p-4 w-1/2 outline-none placeholder-gray-400 dark:placeholder-gray-500 `}
                                                placeholder="Postal Code"
                                                value={paymentData?.postalCode}
                                                onChange={(e) => setPaymentData({ ...paymentData, postalCode: e.target.value })}
                                                />
                                            </div>
                                            </div>

                                        </div>

                                        <div className="flex flex-col mt-6">
                                            <button
                                                className={`flex items-center justify-center gap-3 px-6 py-4  text-white font-semibold ${getBorderRadiusClass()} shadow-md transition-transform transform hover:scale-105`}
                                                style={{
                                                    backgroundColor: brandColor,
                                                    boxShadow: `0px 10px 15px -3px ${brandColor}66, 0px 4px 6px -4px ${brandColor}33`,
                                                }}
                                                onClick={handlePayNow}
                                                disabled={isExpired || isProcess}
                                            >
                                                <BsLockFill className="h-5 w-5" />
                                                <span style={{ fontFamily: selectedFont }} > Pay Now</span>
                                            </button>
                                        </div>
                                    </>   
                                ): paymentType === 'pix-payment' ? (
                                    <>
                                        <h3 className="text-xl font-semibold mb-4 relative text-center" style={{ fontFamily: selectedFont }}>
                                                <span className="absolute left-0 top-1/2 w-full border-t border-gray-300" style={{ transform: 'translateY(-50%)' }}></span>
                                                <span className="bg-white px-2 relative z-10" style={{ fontFamily: selectedFont }} >Pay with PIX</span>
                                        </h3>
                                        <div className="flex gap-4 mb-5">
                                            <div className="flex flex-col w-full">
                                                <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                    First Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                    style={{ fontFamily: selectedFont }}
                                                    placeholder="first name"
                                                    value={pixPaymentData?.first_name}
                                                    onChange={(e) => setPixPaymentData({ ...pixPaymentData, first_name: e.target.value })}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                style={{ fontFamily: selectedFont }}
                                                placeholder="first name"
                                                value={pixPaymentData?.last_name}
                                                onChange={(e) => setPixPaymentData({ ...pixPaymentData, last_name: e.target.value })}
                                            />
                                        </div>
                                        {amountNotSet && (
                                            <div className="flex flex-col w-full">
                                                <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                    Amount
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                    style={{ fontFamily: selectedFont }}
                                                    placeholder="amount"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex gap-4 mb-5">
                                        <div className="flex flex-col w-full">
                                            <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                Identification Number
                                            </label>
                                            <input
                                                type="text"
                                                className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                style={{ fontFamily: selectedFont }}
                                                placeholder="Identification Number"
                                                value={pixPaymentData?.identification_number}
                                                onChange={(e) => setPixPaymentData({ ...pixPaymentData, identification_number: e.target.value })}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                Identification Type
                                            </label>
                                            <input
                                                type="text"
                                                className={`border p-4 w-full  ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                style={{ fontFamily: selectedFont }}
                                                placeholder="Identification Type"
                                                value={pixPaymentData?.identification_type}
                                                onChange={(e) => setPixPaymentData({ ...pixPaymentData, identification_type: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-6">
                                        <button
                                            className={`flex items-center justify-center gap-3 px-6 py-4  text-white font-semibold ${getBorderRadiusClass()} shadow-md transition-transform transform hover:scale-105`}
                                            style={{
                                                backgroundColor: brandColor,
                                                boxShadow: `0px 10px 15px -3px ${brandColor}66, 0px 4px 6px -4px ${brandColor}33`,
                                            }}
                                            onClick={handlePayNow}
                                            disabled={isExpired || isProcess}
                                        >
                                            <BsLockFill className="h-5 w-5" />
                                            <span style={{ fontFamily: selectedFont }} > Pay Now</span>
                                        </button>
                                    </div>
                                    </>
                                ): (
                                <></>  
                                )}
                                <div className="flex items-center justify-center mt-6 text-sm text-gray-500 dark:text-gray-400" style={{ fontFamily: selectedFont }} >
                                    Powered by {mainlogo}
                                </div>
                            </div>
                        </div>
                    </>
                ) : successPreview ? (
                    <div
                        className="flex flex-col items-center justify-center w-full h-[100vh] p-5 rounded-lg"
                        style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                    >
                        <AiFillCheckCircle className="w-20 h-20 mb-4" />
                        <span className="text-xl font-semibold mb-4">Payment Success</span>
                    </div>
                ) : pendingPreview ? (
                    <div
                        className="flex flex-col items-center justify-center w-full h-[100vh] p-5 rounded-lg"
                        style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                    >
                        <AiFillExclamationCircle  className="w-20 h-20 mb-4" />
                        <span className="text-xl font-semibold mb-4">Payment Pending</span>
                    </div>
                ) : failedPreview ? (
                    <div
                        className="flex flex-col items-center justify-center w-full h-[100vh] p-5 rounded-lg"
                        style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                    >
                        <AiFillCloseCircle  className="w-20 h-20 mb-4" />
                        <span className="text-xl font-semibold mb-4">Payment Failed</span>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            )
        ) : (
            <div className="flex flex-col lg:flex-row  mx-auto h-[100vh]">
                <div className="h-full md:w-2/6 lg:w-2/6 sm:w-full border-r-2 p-5" style={{ backgroundColor: brandColor , color : '#FFFFFF' }}>
                    <div className="flex flex-col h-full justify-center items-center gap-6">
                        <img
                        style={{
                            height: "auto",
                            width: hostname === "pay.paxum.com" ? "80px" : "150px",
                            filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.15))",
                        }}
                        src={logos[hostname] || bm2payLogo}
                        alt="Gateway Image"
                        />
                        <div className="text-center">
                        <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>Fast. Secure. Reliable.</h3>
                        <p className="text-lg text-indigo-200 italic" style={{ fontFamily: selectedFont }}>Your trusted gateway for seamless payments.</p>
                        </div>
                    </div>
                </div>
                <div  className="h-full md:w-4/6 lg:w-4/6 sm:w-full px-14 py-10">
                    <div className="flex flex-col h-full justify-center items-center">
                        <h3 className="text-xl font-semibold mb-4 relative text-center" style={{ fontFamily: selectedFont }}>
                            <span className="bg-white px-2 relative z-10" style={{ fontFamily: selectedFont }} >Payment link already used</span>
                        </h3>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default CheckoutPage
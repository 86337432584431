import React, { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { getId } from "utils/auth";
import { getSingleRole } from "api/role";
import { getAllSubscription, getSubscriptuionsFilter } from "api/subscription";
import { getAllGateways } from "api/gateways";
import SubscriptionList from "./components/subscriptionlist";
import { ClientContext } from "ClientProvider";
export type SubscriptionObj = {
  start_date: string;
  interval: string;
  managed_by:any;
  duration: number;
  amount: number;
  gateway_hash: string;
  currency: string;
  gateway_name: string;
  descriptor: string;
  paid_payments: number;
  transaction: string;
  billing_cycles: number;
  status: string;
  id: string;
  client: string;
  client_id:string;
  client_secret:string;
  subscription_id: string;
  subscription_provider : string;
  subscription_provider_name : string;
  payment: any;
  created_at: any;
  next_payment_date: any;
};
const Subscription = () => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionObj[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [statusChanged,setStatusChanged] = useState(false);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const [allGateways, setAllGateways] = useState();
  const { singleRoleData, setSingleRoleData } = useContext(ClientContext);
  const [filter, setFilter] = useState({
    clients: [],
    interval: [],
    duration: null,
    status: [],
  });
  const fetchSubscriptions = () => {
    setIsLoading(true);
    if (pageSize === null || pageSize === undefined) {
      setIsLoading(false);
      return;
    }
    const params = { page: page, per_page: pageSize, searchTexts: searchTexts };
    getAllSubscription(params)
      .then((data) => {
        setSubscriptions(data?.subscriptions ?? []);
        setTotalPage(data.total_pages);
        setTotalItems(data.total_items);
        setCurrentPage(data.current_page);
      })
      .catch((err) => {
        setSubscriptions([]);
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching organizations"
        // );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchAllGateways = () => {
    setIsLoading(true);
    getAllGateways()
      .then((data) => {
        setAllGateways(data);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching AllGateways"
        // );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchRole = () => {
    getSingleRole(getId())
      .then((data) => {
        setSingleRoleData(data ?? []);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching role"
        // );
      })
      .finally(() => {});
  };
  useEffect(() => {
    // fetchRole();
    fetchAllGateways();
  }, []);
  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";
    if (search_txt !== searchTexts && searchTexts !== "") {
      await setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      await setSearchTexts(search_txt);
    }
  };

  const filterData = () => {
    const status = filter?.status?.map((m: any) => m.value);
    const clients = filter?.clients?.map((m: any) => m.value);
    const interval = filter?.interval?.map((m: any) => m.value);
    const duration = filter?.duration;
    setIsLoading(true);
    getSubscriptuionsFilter(
      page,
      pageSize,
      searchTexts,
      clients,
      interval,
      duration,
      status
    )
      .then((data) => {
        setSubscriptions(data?.subscriptions ?? []);
        setTotalPage(data.total_pages);
        setTotalItems(data.total_items);
        setCurrentPage(data.current_page);
      })
      .catch((err) => {
        if (err?.response?.status == 404) {
          setSubscriptions([]);
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching clients"
        // );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (page !== 0 && searchTexts === "") {
      if (filter?.status) {
        filterData();
      }
    } else {
      fetchSearchs();
    }
  }, [filter?.status, filter?.clients, filter?.duration, filter?.interval,statusChanged]);

  useEffect(() => {
    fetchSearchs();
  }, [searchTexts, page, pageSize,statusChanged]);

  const fetchSearchs = () => {
    setIsLoading(true);
    fetchSubscriptions();
  };
  return (
    <>
      <div className="mt-5">
        <SubscriptionList
          pageSize={pageSize}
          setPageSize={setPageSize}
          data={subscriptions}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          totalpage={totalpage}
          totalItems={totalItems}
          currentPage={currentPage}
          onValueChange={serchboxValueChange}
          roleData={singleRoleData}
          allGateways={allGateways}
          fetchSubscriptions={fetchSubscriptions}
          filterData={filterData}
          filter={filter}
          setFilter={setFilter}
          setStatusChanged={setStatusChanged}
          setIsLoading={setIsLoading}
        />
      </div>
    </>
  );
};

export default Subscription;
